.table {
  .avatar-group .avatar {
    margin-right: 4px;
  }
  .badge i {
    margin-right: 10px;
  }
}
.my-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.my-table tbody tr:nth-child(odd) {
  background-color: white;
}
